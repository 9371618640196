import React from "react";
import "../styles/project.css";
import Timer from "./Timer";

function Project({ project, request }) {
  const { name, active, start, value } = project;
  let classes = "card project-card";
  if (active) classes += " active";
  return (
    <div
      onClick={() => request()}
      className={classes}
      style={{
        height: "11rem",
        width: "11rem",
        cursor: "pointer",
      }}
    >
      <div className="card-body p-0 d-flex flex-column justify-content-center position-relative">
        <h5 className="title-text text-center">{name}</h5>
        <h5 className="time-text">
          <Timer project={{ active, start, value }} />
        </h5>
      </div>
    </div>
  );
}

export default Project;
