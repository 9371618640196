import React, { Component } from "react";
import Project from "./Project";

import "../styles/project.css";

class Projects extends Component {
  handleRequest(projectIndex) {
    const { projects, funcs, sheetIndex, userId } = this.props;
    const active = projects[projectIndex].active;
    if (active) {
      funcs.end({ variables: { projectIndex, sheetIndex, userId } });
    } else {
      funcs.start({ variables: { projectIndex, sheetIndex, userId } });
    }
  }

  render() {
    const { projects } = this.props;
    return (
      <div className="project-grid mb-5">
        {projects.map((project, index) => (
          <Project
            key={project.name}
            project={project}
            request={() => this.handleRequest(index)}
          />
        ))}
      </div>
    );
  }
}

export default Projects;
