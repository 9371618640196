import React, { Component } from "react";
import Media from "react-media";

class Buttons extends Component {
  loadData() {
    let { functs, userId, sheetIndex } = this.props;
    functs.load({ variables: { userId, sheetIndex } });
  }

  resetData() {
    let { functs, userId, sheetIndex } = this.props;
    functs.reset({ variables: { userId, sheetIndex } });
  }

  openExcel() {
    let { sheet } = this.props;
    let a = document.createElement("a");
    a.href = "https://docs.google.com/spreadsheets/d/" + sheet._id;
    a.target = "blank";
    console.log(a);
    a.click();
  }

  render() {
    return (
      <Media
        queries={{
          small: "(max-width: 599px)",
          medium: "(min-width: 600px) and (max-width: 1199px)",
          large: "(min-width: 1200px)",
        }}
      >
        {(matches) => (
          <div style={headStyle}>
            <button
              onClick={() => this.loadData()}
              className="btn btn-warning m-3"
              style={{
                float: "left",
                fontSize: "1.5rem",
                color: "#060606",
              }}
            >
              Sync Time
            </button>
            {!matches.small && (
              <button
                onClick={() => this.openExcel()}
                className="btn btn-warning m-3"
                style={{
                  fontSize: "1.5rem",
                  color: "#060606",
                }}
              >
                Open Excel
              </button>
            )}

            <button
              onClick={() => this.resetData()}
              className="btn btn-warning m-3"
              style={{
                float: "right",
                fontSize: "1.5rem",
                color: "#060606",
              }}
            >
              Load Excel
            </button>
          </div>
        )}
      </Media>
    );
  }
}

let headStyle = {
  position: "relative",
  display: "flex",
  justifyContent: "space-between",
  top: 0,
  left: 0,
  width: "100vw",
  fontSize: "1.1rem",
};

export default Buttons;
