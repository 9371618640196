import React from "react";

export default function Loading() {
  return (
    <div
      className="d-flex align-items-center"
      style={{ position: "absolute", height: "100vh" }}
    >
      <h3 className="p-5 row">Loading</h3>
    </div>
  );
}
