import React, { Component } from "react";
import Querries from "./Querries";

class Sheets extends Component {
  constructor(props) {
    super(props);
    this.state = { active: 0 };
  }
  changeActive(vis) {
    if (this.state.active === vis) {
      this.setState({ active: null });
    } else {
      this.setState({ active: vis });
    }
  }

  render() {
    const { active } = this.state;
    const { userId } = this.props.match.params;
    return (
      <Querries
        active={active}
        userId={userId}
        change={(vis) => this.changeActive(vis)}
      />
    );
  }
}

export default Sheets;
